import React from 'react'
import { useLoginUrl, useMe } from 'hooks'
import {
  Market as MarketIcon,
  Campus as CampusIcon,
  Kitchen as KitchenIcon,
  Kiosk as KioskIcon,
  Admin as AdminIcon,
} from 'icons'
import { Box, Page, Text } from 'components'
import { AppCard } from './AppCard'

export const Home: React.FC = () => {
  const { me, hasMarketAccess, hasKioskAccess, hasKitchenAccess, hasCampusAccess, hasLuxoAccess } = useMe()
  const marketLoginUrl = useLoginUrl('market')
  const campusLoginUrl = useLoginUrl('campus')
  const kitchenLoginUrl = useLoginUrl('kitchen')
  const kioskLoginUrl = useLoginUrl('kiosk')
  const luxoLoginUrl = useLoginUrl('luxo')

  return <Page autoHeight fullWidth>
    <Text variant="h3" mb={2}>Hi {me.firstName}!</Text>
    <Text variant="h5" mb={2}>Jump back in</Text>

    <Box display="flex" justifyContent="space-around" flexWrap="wrap" gap={2}>
      { hasKioskAccess && <AppCard
        loginUrl={kioskLoginUrl}
        icon={<KioskIcon />}
        color={(theme) => theme.palette.kiosk.dark}
        backgroundColor={(theme) => theme.palette.kiosk.light}
        title="Cafeteria Kiosk"
        description="View school’s orders and handout food."
      /> }

      { hasKitchenAccess && <AppCard
        loginUrl={kitchenLoginUrl}
        icon={<KitchenIcon />}
        color={(theme) => theme.palette.kitchen.dark}
        backgroundColor={(theme) => theme.palette.kitchen.light}
        title="Kitchen Manager"
        description="Review orders, menu, recipes, and more."
      /> }

      { hasCampusAccess && <AppCard
        loginUrl={campusLoginUrl}
        icon={<CampusIcon />}
        color={(theme) => theme.palette.campus.dark}
        backgroundColor={(theme) => theme.palette.campus.light}
        title="School Admin"
        description="Manage your lunch program, documents, and more."
      /> }

      { hasMarketAccess && <AppCard
        loginUrl={marketLoginUrl}
        icon={<MarketIcon />}
        color={(theme) => theme.palette.market.dark}
        backgroundColor={(theme) => theme.palette.market.light}
        title="Order Meals"
        description="Create orders and manage your family account."
      /> }

      { hasLuxoAccess && <AppCard
        loginUrl={luxoLoginUrl}
        icon={<AdminIcon />}
        color={(theme) => theme.palette.luxo.main}
        backgroundColor={(theme) => theme.palette.luxo.light}
        title="Ordo Admin"
        description="Internal tools for Ordo team members only."
      /> }
    </Box>
  </Page>
}

export default Home
