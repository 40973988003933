import React from 'react'
import { Box, Card, CardHeader, CardContent, Link, Text } from 'components'
import type { Property } from 'csstype'
import type { ResponsiveStyleValue } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import ArrowCircleRight from '@mui/icons-material/ArrowCircleRight'

export type AppCardProps = {
  loginUrl: string
  title: string
  description: string
  icon: React.ReactElement
  color: ResponsiveStyleValue<string[] | Property.Color> | ((theme: Theme) => ResponsiveStyleValue<string[] | Property.Color>)
  backgroundColor: ResponsiveStyleValue<string[] | Property.Color> | ((theme: Theme) => ResponsiveStyleValue<string[] | Property.Color>)
}

export const AppCard: React.FC<AppCardProps> = ({ loginUrl, title, description, icon, color, backgroundColor }) => {
  return <Card sx={{ flexGrow: 1, flexBasis: { xs: '100%', md: '40%', xl: '20%' } }}>
    <CardHeader
      title={<Box display="flex" alignItems="center" width="100%" px={3} pt={3} pb={2}>
        <Link href={loginUrl} sx={{ color: 'inherit', backgroundColor: backgroundColor,  p: 1, lineHeight: '1rem' }}>{ icon }</Link>

        <Link href={loginUrl} pl={3} flexGrow={1} textAlign="left" ><Text variant="h5" fontWeight="medium">{ title }</Text></Link>

        <Link href={loginUrl} sx={{ ml: 'auto',  p: 1, lineHeight: '1rem' }}><ArrowCircleRight fontSize="large" /></Link>
      </Box>}
      titleTypographyProps={{
        color: color,
      }}
      sx={{ p: 0 }}
    />
    <CardContent sx={{ py: 0, px: 3, ':last-child': { py: 0 } }}>
      <Link href={loginUrl} sx={{ color: 'inherit', fontWeight: 'normal', display: 'inline-block', width: '100%', pt: 2, pb: 3, my: 0 }}>{ description }</Link>
    </CardContent>
  </Card>
}

export default AppCard
